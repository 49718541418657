<template>
  <div class="goods_list">
    <van-search
      v-model="search"
      shape="round"
      placeholder=""
      :clearable="false"
      class="goods_search"
    >
      <template #left-icon>
        <svg-icon icon-class="home_nav_search_icon" />
      </template>
      <template #left>
        <svg-icon
          icon-class="navbar_dcs_back"
          style="margin-right: 10px"
          @click="$router.back()"
        />
      </template>
      <template #right-icon>
        <button @click="search_btn">搜索</button>
      </template>
    </van-search>
    <div class="top van-hairline--bottom">
      <div
        class="condition"
        v-if="!$route.query.brandId && !$route.query.collectOrder"
      >
        <!-- this.$route.query.collectOrder -->
        <button
          :style="pitchOnCondition === 0 ? 'border-radius:10px 10px 0 0 ' : ''"
          @click="(pitchOnCondition = 0), (moreCondition = true)"
        >
          品牌
          <svg-icon
            icon-class="down_menu_icon"
            :style="pitchOnCondition === 0 ? 'transform: rotate(0deg);' : ''"
          />
          <span v-show="pitchOnCondition === 0"></span>
        </button>
        <button
          :style="pitchOnCondition === 1 ? 'border-radius:10px 10px 0 0 ' : ''"
          @click="(pitchOnCondition = 1), (moreCondition = true)"
        >
          最新上架
          <svg-icon
            icon-class="down_menu_icon"
            :style="pitchOnCondition === 1 ? 'transform: rotate(0deg);' : ''"
          />
          <span v-show="pitchOnCondition === 1"></span>
        </button>
        <button
          :style="pitchOnCondition === 2 ? 'border-radius:10px 10px 0 0 ' : ''"
          @click="(pitchOnCondition = 2), (moreCondition = true)"
        >
          产地
          <svg-icon
            icon-class="down_menu_icon"
            :style="pitchOnCondition === 2 ? 'transform: rotate(0deg);' : ''"
          />
          <span v-show="pitchOnCondition === 2"></span>
        </button>
        <svg-icon
          icon-class="screening_default_icon"
          @click="crossbarSwitch = !crossbarSwitch"
          :style="crossbarSwitch ? 'color:#009A4D' : 'color:#666666'"
        />
      </div>
      <div class="sort" :style="$route.query.brandId ? 'margin:0' : ''">
        <div>
          <p @click="switchover(0)" :class="index === 0 ? 'pitch_on' : ''">
            综合排序
          </p>
          <p @click="switchover(1)" :class="index === 1 ? 'pitch_on' : ''">
            销量
            <span>
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 1 && order ? 'pitch_on' : ''"
              />
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 1 && !order ? 'pitch_on' : ''"
              />
            </span>
          </p>
          <p @click="switchover(2)" :class="index === 2 ? 'pitch_on' : ''">
            价格
            <span>
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 2 && order ? 'pitch_on' : ''"
              />
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 2 && !order ? 'pitch_on' : ''"
              />
            </span>
          </p>
        </div>

        <svg-icon
          v-if="$route.query.collectOrder"
          icon-class="screening_default_icon"
          @click="crossbarSwitch = !crossbarSwitch"
          :style="crossbarSwitch ? 'color:#009A4D' : 'color:#666666'"
        />
      </div>
      <div class="option_price" v-if="$route.query.collectOrder">
        <button
          @click="selectPrice(item)"
          v-for="item in optionPrice"
          :key="item.start"
          :style="
            priceStart === item.start ? 'background: #009a4d; color:#fff' : ''
          "
        >
          {{ item.val }}
        </button>
      </div>
    </div>
    <div class="more" v-if="!$route.query.brandId">
      <transition name="van-fade">
        <div
          class="mask"
          v-show="moreCondition"
          @click="moreCondition = false"
        ></div>
      </transition>
      <div
        class="condition_content"
        :style="
          pitchOnCondition === 0
            ? 'max-height: 214px;'
            : 'max-height:0;padding:0'
        "
      >
        <template v-if="brand.length">
          <p
            v-for="item in brand"
            :key="item.id"
            @click="selectBrand(item.id)"
            :style="item.id === onBrandId ? 'color:#009A4D' : ''"
          >
            {{ item.brandName }}
          </p>
        </template>
        <van-empty description="" v-else />
      </div>
      <div
        class="condition_content"
        :style="
          pitchOnCondition === 1
            ? 'max-height: 214px;'
            : 'max-height:0;padding:0'
        "
      >
        <p
          v-for="item in time"
          :key="item"
          @click="selectDay(item)"
          :style="item == onTime ? 'color:#009A4D' : ''"
        >
          {{ item }}天以内
        </p>
      </div>
      <div
        class="condition_content"
        :style="
          pitchOnCondition === 2
            ? 'max-height: 214px;'
            : 'max-height:0;padding:0'
        "
      >
        <p
          v-for="(item, index) in city"
          :key="index"
          @click="selectCity(index)"
          :style="item == onCity ? 'color:#009A4D' : ''"
        >
          {{ item }}
        </p>
      </div>
    </div>
    <div
      ref="goods_list"
      class="bottom"
      :style="[
        { display: crossbarSwitch ? 'block' : '' },
        { padding: crossbarSwitch ? '12px 0' : '' },
        {
          top: $route.query.brandId
            ? '0.93rem'
            : $route.query.collectOrder
            ? '1.29rem'
            : '',
        },
        {
          bottom: $route.query.collectOrder ? '0.5rem' : '0',
        },
      ]"
    >
      <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="goods.length ? '到底了，没有更多商品~' : ''"
        @load="getGoodsList"
        error-text="请求失败，点击重新加载"
        offset="-10"
      >
        <div
          class="content"
          id="goods_list"
          :style="
            crossbarSwitch
              ? '	padding: 12px 15px;background: #fff;'
              : 'background: #f5f5f5;'
          "
          v-if="goods.length"
        >
          <div v-show="loading">加载中</div>

          <goods
            v-for="(item, index) in goods"
            :key="index"
            :goodsInfo="item"
            :Switch="crossbarSwitch"
          />
        </div>
        <p class="pull_up" v-if="!(error || finished || loading)">
          上拉 查看更多商品 <svg-icon icon-class="home_more_icon" />
        </p>
      </van-list>

      <!-- </van-pull-refresh> -->
      <van-empty description="暂无商品" v-show="!loading && !goods.length" />
    </div>
    <div class="tips van-hairline--top" v-if="$route.query.collectOrder">
      <!-- 再购1元，平台积分可抵扣10元 -->
      <van-button
        round
        color="#009A4D"
        @click="$router.replace('home/shoppingCart')"
        >去购物车（{{ quantity > 99 ? "99+" : quantity }}）</van-button
      >
    </div>
    <functionButton  :domNode="$refs.goods_list" />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import { mapState } from "vuex";

export default {
  name: "goods-list",
  components: { Goods },
  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "tzInfo",
      "quantity",
      "change",
    ]),
  },
  data() {
    return {
      search: "",
      moreCondition: false, //品牌、上架、产地条件显示隐藏
      pitchOnCondition: -1, //品牌、上架、产地条件
      order: true, //desc asc 升降序
      index: 0,
      isLoading: false,
      brand: [],
      onBrandId: "",
      time: [7, 30, 90],
      onTime: 0,
      city: ["北京", "上海", "广东", "福建", "湖南"],
      onCity: "",
      goods: [],
      sortColumn: "", //查询条件
      pageNum: 1,
      crossbarSwitch: false,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      refresh: false,
      scrollTop: 0,

      optionPrice: [
        {
          val: "0-30元",
          start: 0,
          end: 30,
        },
        {
          val: "30-60元",
          start: 30,
          end: 60,
        },
        {
          val: "60-100元",
          start: 60,
          end: 100,
        },
        {
          val: "100元以上",
          start: 100,
          end: "",
        },
      ],
      priceStart: "",
      priceEnd: "",
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.like) {
          this.search = val.query.like;
        } else if (val.query.brandId) {
        }
      },
      deep: true,
      immediate: true,
    },
    moreCondition(val, oldVal) {
      if (!val) {
        this.pitchOnCondition = -1;
      }
    },
    crossbarSwitch(val) {
      this.$nextTick(function () {
        if (this.goods.length) this.waterFall();
      });
    },
  },
  created() {
    this.$store.commit("user/ShoppingCartSumChange", false);
    // this.getGoodsList();
  },
  activated() {
    if (this.change) {
      this.goods = [];
      this.pageNum = 1;
      this.getGoodsList();
    }
    window.addEventListener("scroll", this.windowScroll, true);
    document.getElementsByClassName("bottom")[0].scrollTop = this.scrollTop;
  },
  deactivated() {
    window.removeEventListener("scroll", this.windowScroll, true);
    this.search = "";
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    if (from.name != "goods-details") {
      next((vm) => {
        vm.refresh = true;
        vm.scrollTop = 0;
        vm.crossbarSwitch = false;
        vm.order = true;
        vm.pitchOnCondition = -1;
        vm.moreCondition = false;
        vm.index = 0;
        vm.onTime = 0;
        vm.onCity = "";
        vm.sortColumn = "";
        vm.pageNum = 1;
        vm.getGoodsList();
      });
    }

    next();
  },
  methods: {
    windowScroll() {
      this.scrollTop = document.getElementsByClassName("bottom")[0].scrollTop;
    },
    search_btn() {
      if (this.search != this.$route.query.like)
        this.$router.replace({
          path: "/goodsList",
          query: {
            like: this.search,
            brandId: this.$route.query.brandId,
            collectOrder: this.$route.query.collectOrder
              ? this.$route.query.collectOrder
              : undefined,
            taskId: this.$route.query.taskId
              ? this.$route.query.taskId
              : undefined,
          },
        });
      this.refresh = true;
      this.getGoodsList();
    },
    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1: //销量
          this.order = !this.order;
          break;
        case 2: //价格
          this.order = !this.order;
          this.sortColumn = "retailPrice";
          break;
        default:
          break;
      }
      if (this.index === 1) return;
      //还没销量
      this.refresh = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getGoodsList();
      }, 500);
    },
    onRefresh() {
      setTimeout(() => {
        // Toast('刷新成功');
        this.isLoading = false;
      }, 1000);
    },
    async getGoodsList() {
      if (this.refresh) {
        this.goods = [];
        this.pageNum = 1;
        this.finished = false;
      }
      this.loading = true;
      await this.$API.homePage
        .CommList({
          object: {
            brandId: this.$route.query.brandId
              ? this.$route.query.brandId
              : this.onBrandId, //查品牌相关商品传品牌id
            like: this.$route.query.like, //名字
            location: "", //广告
            order: this.order ? "desc" : "asc", // desc/asc
            productionPlace: this.onCity, //产地
            queryCriteria: -this.onTime, //时间排序
            sortColumn: this.sortColumn, // 查询条件
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
            priceStart: this.priceStart, //商品价格开始
            priceEnd: this.priceEnd, //商品价格结束
            deduction: this.$route.query.collectOrder ? 1 : "", //抵扣平台积分
          },
          pageNum: this.pageNum,
          pageSize: 10,
        })
        .then((res) => {
          !this.$route.query.brandId && this.getGoodsBrand();
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);
          res.data.data.forEach((a) => {
            a.filePath = a.filePath.split(",")[0].endsWith(".mp4")
              ? a.filePath.split(",")[1]
              : a.filePath.split(",")[0];
            if (
              a.orderCartListItems.length &&
              !a.orderCartListItems[0].isDelete
            ) {
              a.quantity = a.orderCartListItems[0].quantity;
            } else {
              a.quantity = 0;
            }
          });
          if (this.refresh) {
            this.goods = res.data.data;
          } else {
            res.data.data.forEach((goods) => {
              this.goods.push(goods);
            });
          }

          this.pages = res.data.pages;
          this.pageNum++;

          // 版本2
          this.$nextTick(function () {
            if (res.data.data.length) this.waterFall();
          });
          if (this.pageNum > this.pages) {
            this.finished = true;
          }
          this.refresh = false;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.message);
          this.error = true;
          this.loading = false;
        });
      this.$store.commit("user/ShoppingCartSumChange", false);
    },
    waterFall() {
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");
      //获取元素的宽度(含border，padding)
      if (this.crossbarSwitch || !item.length) {
        container.style.height = `auto`;
        return;
      }
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left = container.offsetWidth - width * columnCount; //宽度间隔
      let height = 12; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }
      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
    // async getGoodsList2() {
    //   await this.$API.homePage
    //     .CommList({
    //       object: {
    //         brandId: this.$route.query.brandId
    //           ? this.$route.query.brandId
    //           : this.onBrandId, //查品牌相关商品传品牌id
    //         like: this.$route.query.like, //名字
    //         location: "", //广告
    //         order: this.order ? "desc" : "asc", // desc/asc
    //         productionPlace: this.onCity, //产地
    //         queryCriteria: -this.onTime, //时间排序
    //         sortColumn: this.sortColumn, // 查询条件
    //       },
    //       pageNum: this.pageNum,
    //       pageSize: 10,
    //     })
    //     .then((res) => {
    //       res.data.data.map((a) => {
    //         return {
    //           ...a,
    //           filePath: a.filePath.split(",")[0],
    //         };
    //       });
    //       this.pages = res.data.pages;
    //       this.goods = res.data.data;
    //       this.loading = false;
    //       if (this.pageNum > this.pages) {
    //         this.finished = true;
    //       }
    //     })
    //     .catch(() => {
    //       console.log(err);
    //       this.error = true;
    //       this.loading = false;
    //     });
    // },
    async getGoodsBrand() {
      const data = await this.$API.brand.AllBrand({
        object: {
          name: this.$route.query.like,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.brand = data.data.data;
    },
    selectBrand(val) {
      if (this.onBrandId == val) {
        this.onBrandId = "";
      } else {
        this.onBrandId = val;
      }
      this.refresh = true;
      this.moreCondition = false;
      this.getGoodsList();
    },
    selectDay(val) {
      if (this.onTime == val) {
        this.onTime = 0;
      } else {
        this.onTime = val;
      }
      this.refresh = true;
      this.moreCondition = false;
      this.getGoodsList();
    },
    selectCity(val) {
      if (this.onCity == this.city[val]) {
        this.onCity = "";
      } else {
        this.onCity = this.city[val];
      }
      this.refresh = true;
      this.moreCondition = false;
      this.getGoodsList();
    },
    selectPrice(val) {
      if (val.start === this.priceStart) {
        this.priceStart = "";
        this.priceEnd = "";
      } else {
        this.priceStart = val.start;
        this.priceEnd = val.end;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.refresh = true;
        this.getGoodsList();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.goods_list {
  ::v-deep .goods_search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    padding: 10px 15px;
    z-index: 100;
    .van-search__content {
      height: 30px;
      background-color: #f2f2f2;
      .van-cell {
        padding: 0;
        align-items: center;
        .van-field__left-icon {
          margin-right: 8px;
          .svg-icon {
            color: #333333;
          }
        }
        .van-cell__value {
          .van-field__right-icon {
            position: absolute;
            right: 0;
            margin: 0;
            padding: 0;
            button {
              border: none;
              padding: 3px 16px;
              border-radius: 16px;
              top: 0;
              z-index: 9999;
              background: linear-gradient(180deg, #0ae377 0%, #009a4d 100%);
              color: #fff;
            }
          }
        }
        .van-field__control {
          font-size: 12px;
          padding-right: 70px;
        }
        .van-field__control::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #999999;
        }
        .van-field__control:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #999999;
        }
        .van-field__control::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #999999;
        }
        .van-field__control:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #999999;
        }
      }
    }
  }

  .top {
    max-width: 500px;
    margin: 0 auto;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    background: #fff;
    padding: 12px 15px;
    z-index: 100;
    .condition {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background: #fff;
      z-index: 100;
      margin-bottom: 12px;

      button {
        display: flex;
        align-items: center;
        height: 20px;
        background: #f5f5f5;
        border-radius: 10px;
        line-height: 20px;
        color: #666666;
        border: none;
        padding: 0 12px;
        position: relative;
        .svg-icon {
          transform: rotate(-180deg);
          font-size: 8px;
          margin-left: 4px;
          transition: 0.3s;
        }
        span {
          position: absolute;
          top: 20px;
          width: 100%;
          left: 0;
          height: 10px;
          background: #f5f5f5;
          z-index: 91;
        }
      }
    }

    .sort {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        p {
          display: flex;
          color: #666666;
          margin-right: 46px;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 4px;
            .svg-icon {
              font-size: 7px;
              font-weight: bold;
              color: #666666;
            }
            .svg-icon:nth-child(1) {
              transform: rotate(-90deg);
            }
            .svg-icon:nth-child(2) {
              transform: rotate(90deg);
            }
            .pitch_on {
              color: #009a4d;
            }
          }
        }
        .pitch_on {
          color: #009a4d;
        }
      }
    }
    .option_price {
      display: flex;
      overflow-x: scroll;
      height: 24px;
      margin-top: 12px;
      button {
        flex-shrink: 0;
        width: 84px;
        height: 24px;
        background: #f5f5f5;
        border-radius: 12px;
        border: none;
        margin-right: 5px;
        color: #666666;
        transition: 0.3s all;
      }
      button:last-child {
        margin-right: 0;
      }
    }
    .option_price::-webkit-scrollbar {
      display: none;
    }
  }
  .more {
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    bottom: 0;
    .mask {
      position: absolute;
      top: -34px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(51, 51, 51, 0.39);
      z-index: 101;
    }
    .condition_content {
      position: absolute;
      top: -34px;
      left: 0;
      right: 0;
      max-width: 500px;
      margin: 0 auto;
      background: #f5f5f5;
      border-radius: 0px 0px 10px 10px;
      z-index: 1000;
      overflow: hidden;
      transition: 0.3s all;
      padding-top: 16px;
      overflow-y: auto;

      p {
        float: left;
        width: 33%;
        margin-bottom: 14px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 10px;
      }
      .van-empty {
        padding-top: 0;
        margin-top: -20px;
      }
    }
  }
  .bottom {
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 125px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 15px;
    background: #f5f5f5;
    // transition: 0.3s all;
    overflow-y: scroll;

    .content {
      overflow-x: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      //版本2
      position: relative;
      width: 100%;
      margin-bottom: 12px;

      .goods {
        margin-bottom: 12px;
      }

      // ::v-deep .van-pull-refresh__track {
      //   width: 100%;
      //   display: flex;
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   justify-content: space-between;
      // }
    }
    .pull_up {
      display: flex;
      // padding-top: 12px;
      font-size: 12px;
      color: #999;
      justify-content: center;
      align-items: center;
      .svg-icon {
        color: #999;
        font-size: 8px;
        margin-left: 4px;
        transform: rotate(90deg);
      }
    }
  }
  .tips {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
  }
}
</style>
